import React from 'react'

import clsx from 'clsx'

import style from './SucceedCheck.module.css'

import { ReactComponent as CheckIcon } from 'assets/icons/check-icon.svg'

type PropsType = {
  size: number
  className?: string
}

export const SucceedCheck = ({ size, className }: PropsType) => {
  return (
    <div className={clsx(style.box, className)} style={{ width: `${size}px`, height: `${size}px` }}>
      <CheckIcon width={`${size / 2 + 1}`} />
    </div>
  )
}
