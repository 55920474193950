import React from 'react'

import { Tooltip } from '@it-incubator/ui-kit'

import s from './AuthOrAvatar.module.css'

import userProfilePlaceholder from 'assets/images/user-profile-placeholder.svg'
import { ButtonLink } from 'common/components/ButtonLink/ButtonLink'
import { useAppSelector, useRedirectLink } from 'common/hooks'
import { selectIsLoggedIn, selectProfile } from 'features/auth/auth-selectors'

export const AuthOrAvatar = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const profile = useAppSelector(selectProfile)

  const redirectLink = useRedirectLink()

  return isLoggedIn ? (
    <Tooltip
      component={
        <div className={s.tooltipBox}>
          <img
            src={userProfilePlaceholder}
            width="36"
            height="36"
            alt="Плейсхолдер для аватара пользователя"
          />
        </div>
      }
    >
      Вы авторизованы как {profile?.system.email || 'Пользователь'}
    </Tooltip>
  ) : (
    <ButtonLink href={redirectLink}>Авторизоваться</ButtonLink>
  )
}
