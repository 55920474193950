import { CoursesEnum } from '../layout/Banner/Banner'

export const CATEGORIES = ['basic', 'extra']

export const Courses: Record<string, string> = {
  '1': 'front-end',
  '3': 'back-end',
}

export const getCourseIdFromMap = (slag: string | undefined): CoursesEnum => {
  return Object.keys(Courses).find(id => Courses[id] === slag) as CoursesEnum
}
