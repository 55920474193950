import axios from 'axios'

const ACCESS_TOKEN_KEY = 'accessToken'

localStorage.removeItem(ACCESS_TOKEN_KEY)

export const incubatorBackendInstance = axios.create({
  baseURL: process.env.REACT_APP_INCUBATOR_API,
  withCredentials: true,
})

incubatorBackendInstance.interceptors.request.use(config => {
  const accessToken = authService.getAccessToken()

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`
  }

  return config
})

export const TOKENS_REFRESH_URL = 'auth/refresh'

export const handleAuthResponseErrors = async (error: any) => {
  const { config: originalReq, response } = error

  if (
    originalReq.url !== TOKENS_REFRESH_URL &&
    !originalReq.isRetryAttempt &&
    response &&
    response.status === 401
  ) {
    await authService.refreshToken()
    originalReq.isRetryAttempt = true

    return await incubatorBackendInstance.request(originalReq)
  } else {
    throw error
  }
}

incubatorBackendInstance.interceptors.response.use(undefined, error =>
  handleAuthResponseErrors(error)
)

class AuthService {
  async me() {
    return incubatorBackendInstance.get<MeResponse>(`auth/me`)
  }

  async refreshToken() {
    const { accessToken } = (await incubatorBackendInstance.post(`auth/refresh`)).data.data

    this.saveAccessToken(accessToken)
  }

  saveAccessToken(accessToken: string) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  }
  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY)
  }
  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
  }
}

export const authService = new AuthService()

export type MeResponse = {
  resultCode: number
  messages: string[]
  data: Profile
}

export type Profile = {
  _id: string
  providers: {
    [key: string]: {
      id: string
      email: string
      username: string
      additionalData: {
        sub: string
        email_verified: boolean
      }
    }
  }
  emails: string[]
  system: {
    username: string
    email: string
  }
}
