import React from 'react'

import { useInitialFetching } from './hooks/useInitialFetching'
import { useSubjectAutoNavigate } from './hooks/useSubjectAutoNavigate'
import { useSyncSearchParams } from './hooks/useSyncSearchParams'
import style from './Menu.module.css'
import { SkeletonMenu } from './SkeletonMenu/SkeletonMenu'

import { useAppSelector } from 'common/hooks'
import { MenuSearch, selectMenuStatus, SprintsList } from 'features/menu'

export const Menu = () => {
  const status = useAppSelector(selectMenuStatus)

  useSubjectAutoNavigate()
  useInitialFetching()
  useSyncSearchParams()

  return (
    <div className={style.box}>
      {status === 'loading' ? (
        <div>
          <SkeletonMenu />
        </div>
      ) : (
        <>
          <MenuSearch />
          <SprintsList />
        </>
      )}
    </div>
  )
}
