// BaseThunkAPI need import
export const thunkTryCatch = async (thunkAPI: any, logic: Function) => {
  try {
    return await logic()
  } catch (e: any) {
    const error = e.response ? e.response.data.message : e.message + ', more details in the console'

    return thunkAPI.rejectWithValue({ error })
  }
}
