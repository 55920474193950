import React, { useState } from 'react'

import { Info, Skeleton } from '@it-incubator/ui-kit'
import clsx from 'clsx'

import style from './Header.module.css'
import { HeaderModal } from './HeaderModal'

import { ReactComponent as LogoImage } from 'assets/logo.svg'
import { useAppSelector } from 'common/hooks'
import { selectAuthStatus } from 'features/auth/auth-selectors'
import { Banner } from 'layout/Banner/Banner'
import { AuthOrAvatar } from 'layout/Header/AuthOrAvatar/AuthOrAvatar'

export const Header = () => {
  const [infoOpen, setInfoOpen] = useState(false)
  const authStatus = useAppSelector(selectAuthStatus)

  return (
    <div>
      <header className={style.header}>
        <div className={clsx('container', style.headerBox)}>
          <LogoImage />

          <div className={clsx(style.buttonBox)}>
            {authStatus === 'loading' ? <Skeleton height={30} width={120} /> : <AuthOrAvatar />}

            <button className={style.infoButton} onClick={() => setInfoOpen(true)}>
              <Info size={36} />
            </button>
          </div>
          <HeaderModal open={infoOpen} onClose={() => setInfoOpen(false)} />
        </div>
      </header>
      <Banner />
    </div>
  )
}
