import { createSlice } from '@reduxjs/toolkit'

import { NodeType, RequestStatus } from 'common/types'
import { fetchSubject } from 'features/content'

export const contentSlice = createSlice({
  name: 'content',
  initialState: {
    subject: null as null | NodeType,
    status: 'idle' as RequestStatus,
  },
  reducers: {
    resetContent(state) {
      state.subject = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSubject.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchSubject.fulfilled, (state, action) => {
        state.subject = action.payload as NodeType
        state.status = 'succeeded'
      })
      .addCase(fetchSubject.rejected, state => {
        state.status = 'failed'
      })
  },
})

export const contentActions = contentSlice.actions
export const contentReducer = contentSlice.reducer
