import { Helmet } from 'react-helmet'

import { useAppSelector } from '../common/hooks'
import { selectCourseId } from '../features/auth/auth-selectors'
import { CoursesEnum } from '../layout/Banner/Banner'

export const Head = () => {
  const courseId = useAppSelector(selectCourseId)

  return (
    <Helmet>
      <meta name="description" content={getDescription(courseId)} />
      <title>{getCourseTitle(courseId)}</title>
    </Helmet>
  )
}

const getCourseTitle = (courseId: CoursesEnum) => {
  switch (courseId) {
    case CoursesEnum.FRONTEND:
      return 'Roadmap для frontend разработчика | IT Incubator'
    case CoursesEnum.BACKEND:
      return 'Roadmap для backend разработчика | IT Incubator'
    default:
      return 'Roadmap'
  }
}

const getDescription = (courseId: CoursesEnum) => {
  switch (courseId) {
    case CoursesEnum.FRONTEND:
      return 'Полезные материалы для развития и обучения фронтенд разработчиков онлайн.'
    case CoursesEnum.BACKEND:
      return 'Полезные материалы для развития и обучения бэкенд разработчиков онлайн.'
    default:
      return 'Полезные материалы для развития и обучения разработчиков онлайн.'
  }
}
