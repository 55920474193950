import { ComponentProps, FC } from 'react'

import style from './ButtonLink.module.css'

type Props = {
  children: string
} & ComponentProps<'a'>

export const ButtonLink: FC<Props> = ({ children, ...props }) => {
  return (
    <a className={style.link} {...props}>
      {children}
    </a>
  )
}
