import { instance } from 'common/api/api'
import { NodeType } from 'common/types'

export const menuAPI = {
  getSprints(courseId: string) {
    return instance.get<NodeType[]>(`roadmap/${courseId}`)
  },
  getSubject({ id }: GetSubjectParams) {
    return instance.get<NodeType>(`roadmap/node/${id}`, {
      params: {
        deep: 3,
      },
    })
  },
}

export type GetSubjectParams = {
  id: string
}
