import React from 'react'

import { ReadMore, Typography } from '@it-incubator/ui-kit'

import style from './TaskContent.module.css'

type PropsType = {
  description: string
  title: string
  link?: string
}

export const TaskContent = ({ description, link, title }: PropsType) => {
  return (
    <div className={style.box}>
      {link ? (
        <a className={style.link} href={link} target="_blank" rel="noreferrer">
          {title}
        </a>
      ) : (
        <Typography.Body2>{title}</Typography.Body2>
      )}
      <p className={style.text}>
        <ReadMore text={description} maxLength={120} />
      </p>
    </div>
  )
}
