import { NodeIdsItem } from '../types'

import { NodeDomainType } from 'features/menu'

const filterNodeIdsItems = (item: NodeIdsItem, level: number) => {
  return item.level <= level && item.category === 'basic'
}

// учитываются только те задачи, которые не превышают неделю по уровню
// учитываются только задачи категории 'basic'
export const calculateResults = (sprints: NodeDomainType[], completedTasks: string[]) => {
  const subjectsCalculatedState = sprints.map(sprint => ({
    ...sprint,
    children: sprint.children?.map(week => ({
      ...week,
      children: week.children?.map(subject => {
        const result =
          subject.nodesIds
            .filter(item => filterNodeIdsItems(item, week.level))
            .filter(item => completedTasks.includes(item.id)).length /
          subject.nodesIds.filter(item => filterNodeIdsItems(item, week.level)).length

        return {
          ...subject,
          result,
          isDone: result === 1,
        }
      }),
    })),
  }))

  const weeksCalculatedState = subjectsCalculatedState.map(sprint => ({
    ...sprint,
    children: sprint.children?.map(week => ({
      ...week,
      isDone: week.children?.every(subject => subject.isDone) || false,
    })),
  }))

  const sprintsCalculatedState = weeksCalculatedState.map(sprint => ({
    ...sprint,
    isDone: sprint.children?.every(week => week.isDone) || false,
  }))

  return sprintsCalculatedState
}
