import React from 'react'

import { Button, Modal } from '@it-incubator/ui-kit'

import style from './HeaderModal.module.css'

type PropsType = {
  open: boolean
  onClose: () => void
}

export const HeaderModal = ({ open, onClose }: PropsType) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Как пользоваться Roadmap"
      size="lg"
      className={style.modal}
    >
      <p className={style.text}>
        Roadmap предназначен для того, чтобы отобразить твой пути обучения и помочь отслеживать
        прогресс.
      </p>
      <p className={style.text}>
        Для того, чтобы прогресс-бар рассчитался, необходимо отметить галочкой то, что ты уже
        изучил(а). Roadmap будет постоянно наполняться контентом по рекомендациям наших
        разработчиков.
      </p>
      <div className={style['button-box']}>
        <Button onClick={onClose}>Понятно</Button>
      </div>
    </Modal>
  )
}
