import { useState } from 'react'

import { NodeDomainType } from 'features/menu'

export const useToggleMenuItem = (item: NodeDomainType): [boolean, (isOpen: boolean) => void] => {
  const [isOpen, setIsOpen] = useState(true)

  // auto closing completed week menu list
  const [prevIsDone, setPrevIsDone] = useState(false)

  if (prevIsDone !== item.isDone) {
    setPrevIsDone(Boolean(item.isDone))
    setIsOpen(!item.isDone)
  }

  return [isOpen, setIsOpen]
}
