import React, { ReactNode } from 'react'

import { CircularProgress } from '@mui/material'
import clsx from 'clsx'

import style from './CircleResult.module.css'

import './CirlceResult.css'

type PropsType = {
  value: number | null
  size?: number
  children?: ReactNode
  textClassName?: string
  isCurrent?: boolean
}

export const CircleResult = ({
  size = 120,
  textClassName,
  children,
  isCurrent = false,
  ...props
}: PropsType) => {
  const value = props.value || 0

  return (
    <div className={clsx(style.box, value === 0 && style.inactive)} style={{ width: `${size}px` }}>
      <CircularProgress
        className={clsx(style.progress)}
        variant="determinate"
        value={value}
        size={size}
        thickness={2}
      />
      <CircularProgress
        className={clsx(
          style['progress-background'],
          'progress-background',
          isCurrent && style.current
        )}
        variant="determinate"
        value={100}
        size={size}
        thickness={2}
      />
      {children || <span className={clsx(style.text, textClassName)}>{`${value || 0}%`}</span>}
    </div>
  )
}
