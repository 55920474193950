import React from 'react'

import { clsx } from 'clsx'

import style from './Banner.module.css'

import backendMobileBannerImage from 'assets/images/banner-backend-mobile.png'
import backendBannerImage from 'assets/images/banner-backend.jpg'
import frontendMobileBannerImage from 'assets/images/banner-frontend-mobile.png'
import frontendBannerImage from 'assets/images/banner-frontend.jpg'
import bannerImage from 'assets/images/banner.jpg'
import { useAppSelector } from 'common/hooks'
import { selectCourseId } from 'features/auth/auth-selectors'

export enum CoursesEnum {
  FRONTEND = '1',
  BACKEND = '3',
}

const EDUCATION_LINK = 'https://it-incubator.io/education'
const FRONTEND_LINK = 'https://it-incubator.io/click/10'
const BACKEND_LINK = 'https://it-incubator.io/click/11'

export const Banner = () => {
  const courseId = useAppSelector(selectCourseId)
  const link = getCourseLink(courseId)

  return (
    <div className={clsx('container', style.box)}>
      <a className={style.link} href={link} target="_blank" rel="noreferrer">
        <picture>
          <source media="(max-width: 600px)" srcSet={getMobileBannerImage(courseId)} />
          <img
            className={style.img}
            src={getBannerImage(courseId)}
            width="1100"
            height="130"
            alt="Учебные группы в IT-Incubator стартуют каждый месяц"
          />
        </picture>
      </a>
    </div>
  )
}

const getBannerImage = (courseId: CoursesEnum) => {
  switch (courseId) {
    case CoursesEnum.FRONTEND:
      return frontendBannerImage
    case CoursesEnum.BACKEND:
      return backendBannerImage
    default:
      return bannerImage
  }
}

const getMobileBannerImage = (courseId: string) => {
  switch (courseId) {
    case CoursesEnum.FRONTEND:
      return frontendMobileBannerImage
    case CoursesEnum.BACKEND:
      return backendMobileBannerImage
    default:
      return bannerImage
  }
}

const getCourseLink = (courseId: string) => {
  switch (courseId) {
    case CoursesEnum.FRONTEND:
      return FRONTEND_LINK
    case CoursesEnum.BACKEND:
      return BACKEND_LINK
    default:
      return EDUCATION_LINK
  }
}
