import React from 'react'

import { Skeleton, Spinner } from '@it-incubator/ui-kit'
import { useParams } from 'react-router-dom'
import useResizeObserver from 'use-resize-observer'

import style from './Content.module.css'
import { useFetchSubject } from './hooks/useFetchSubject'

import { useAppSelector } from 'common/hooks'
import {
  Categories,
  ContentFooter,
  ContentHeader,
  selectContentStatus,
  SubjectNotFound,
} from 'features/content'
import { useSetWeekIdFromSearchParams } from 'features/content/hooks/useSetWeekIdFromSearchParams'

export const Content = () => {
  const { id } = useParams()
  const status = useAppSelector(selectContentStatus)

  useSetWeekIdFromSearchParams()
  useFetchSubject(id)

  // отслеживаем высоту ContentHeader для автоматического пересчёта скролла в Categories
  const { ref, height } = useResizeObserver<HTMLDivElement>({
    box: 'border-box',
  })

  return (
    <section className={style.grid}>
      {status !== 'failed' ? (
        <>
          <ContentHeader key={id} ref={ref} />
          <Categories headerHeight={height} />
          <ContentFooter />
        </>
      ) : (
        <SubjectNotFound />
      )}
    </section>
  )
}
