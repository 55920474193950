import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Profile } from '../../common/api/incubator-api'
import { CoursesEnum } from '../../layout/Banner/Banner'
import { authMe } from '../menu'

import { RequestStatus } from 'common/types'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    courseId: CoursesEnum.FRONTEND,
    status: 'idle' as RequestStatus,
    profile: null as null | Profile,
  },
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<{ isLoggedIn: boolean }>) => {
      state.isLoggedIn = action.payload.isLoggedIn
    },
    setCourseId: (state, action: PayloadAction<{ courseId: CoursesEnum }>) => {
      state.courseId = action.payload.courseId
    },
    setAuthStatus: (state, action: PayloadAction<{ status: RequestStatus }>) => {
      state.status = action.payload.status
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authMe.pending, state => {
        state.status = 'loading'
      })
      .addCase(authMe.fulfilled, (state, action) => {
        state.isLoggedIn = true
        state.status = 'idle'
        state.profile = action.payload
      })
      .addCase(authMe.rejected, state => {
        state.isLoggedIn = false
        state.status = 'failed'
        state.profile = null
      })
  },
})

export const authReducer = authSlice.reducer
export const authActions = authSlice.actions
