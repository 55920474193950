import React from 'react'

import { selectCurrentWeekLevel } from '../../../menu'

import style from './Category.module.css'
import { TasksList } from './TasksList'

import { useAppSelector } from 'common/hooks'
import { selectTasksByCategory } from 'features/content'

type PropsType = {
  category: string
}

const getCategoryTitle = (category: string) => {
  let title = ''

  switch (category) {
    case 'extra':
      title = 'Дополнительный контент'
      break
    default:
      title = 'Основной контент'
  }

  return title
}

export const Category = ({ category }: PropsType) => {
  const tasks = useAppSelector(state => selectTasksByCategory(state, category))

  if (tasks?.length === 0 || !tasks) {
    return null
  }

  const sortedTasks = tasks.sort((prev, next) => prev.level - next.level)

  return (
    <li className={style.box}>
      <h3 className={style.title}>{getCategoryTitle(category)}</h3>
      <TasksList tasks={sortedTasks} />
    </li>
  )
}
