import React from 'react'

import clsx from 'clsx'

import { useToggleMenuItem } from '../../hooks/useToggleMenuItem'

import { SubjectsList } from './SubjectsList'
import style from './Week.module.css'

import { ReactComponent as MenuArrowIcon } from 'assets/icons/menu-arrow.svg'
import { SucceedCheck } from 'common/components'
import { NodeDomainType } from 'features/menu'

type PropsType = {
  week: NodeDomainType
}

export const Week = ({ week }: PropsType) => {
  const isDone = week.isDone
  const [isOpen, setIsOpen] = useToggleMenuItem(week)

  const userLevel = 100
  const isAvailable = week.level <= userLevel

  return (
    <li
      className={clsx(
        style.box,
        isDone && style['box--isDone'],
        isOpen && style['box--open'],
        !isAvailable && style['box--isUnavailable']
      )}
    >
      <div
        className={style['title-box']}
        onClick={() => {
          if (!isDone) return
          setIsOpen(!isOpen)
        }}
      >
        <div className={style.title}>
          {isDone && <SucceedCheck size={16} />}
          <p className={clsx(style.titleText, isDone && style['titleText--isDone'])}>
            {week.title}
          </p>
          {isDone && <MenuArrowIcon className={style['arrow-icon']} width="10" height="5" />}
        </div>
      </div>
      {week.children && (
        <SubjectsList
          subjects={week.children}
          isOpen={isOpen}
          weekLevel={week.level}
          weekId={week.id}
        />
      )}
    </li>
  )
}
