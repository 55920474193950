import { useEffect } from 'react'

import { useSearchParams } from 'react-router-dom'

import { menuActions, selectCurrentWeekId } from '../index'

import { useActions, useAppSelector } from 'common/hooks'

/**
 * Синхронизирует SearchParams со стейтом
 */
export const useSyncSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const weekIdFromState = useAppSelector(selectCurrentWeekId)
  const { setCurrentWeekId } = useActions(menuActions)
  const weekIdFromSearchParams = searchParams.get('weekId')

  useEffect(() => {
    setCurrentWeekId(weekIdFromSearchParams!)
  }, [])

  useEffect(() => {
    if (weekIdFromState) {
      setSearchParams({
        weekId: weekIdFromState,
      })
    }
  }, [weekIdFromState])
}
