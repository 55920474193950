import React from 'react'

import { clsx } from '@it-incubator/ui-kit'

import style from './Footer.module.css'

import { ReactComponent as InstIcon } from 'assets/icons/social/inst.svg'
import { ReactComponent as WebIcon } from 'assets/icons/social/web.svg'
import { ReactComponent as YouTubeIcon } from 'assets/icons/social/youtube.svg'

type PropsType = {}

export const Footer = ({}: PropsType) => {
  return (
    <footer className={style.footer}>
      <div className={clsx('container', style.box)}>
        <p className={style.copyright}>
          © {new Date().getFullYear()}&nbsp;
          <a
            className={style['copyright-link']}
            href="https://it-incubator.io/"
            target="_blank"
            rel="noreferrer"
          >
            It-Incubator
          </a>
          <span className={style['copyright-extra-text']}>. All rights reserved</span>
        </p>
        <ul className={style.social}>
          <li>
            <a
              className={style.link}
              href="https://www.instagram.com/it.incubator/"
              target="_blank"
              rel="noreferrer"
              aria-label="Инстаграм"
            >
              <InstIcon />
            </a>
          </li>
          <li>
            <a
              className={style.link}
              href="https://it-incubator.io/"
              target="_blank"
              rel="noreferrer"
              aria-label="Сайт IT-Incubator"
            >
              <WebIcon />
            </a>
          </li>
          <li>
            <a
              className={style.link}
              href="https://www.youtube.com/c/ITINCUBATOR"
              target="_blank"
              rel="noreferrer"
              aria-label="Youtube"
            >
              <YouTubeIcon />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}
