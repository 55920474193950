import { useParams } from 'react-router-dom'

import { useAppSelector } from 'common/hooks'
import { selectCurrentWeekId } from 'features/menu'

type Params = {
  weekId?: string
  subjectId: string
}
/**
 * Определяет, что в url находится subject данной недели
 */
export const useIsCurrentSubject = ({ weekId, subjectId }: Params) => {
  const { id } = useParams()

  return subjectId === id
}
