import React from 'react'

import clsx from 'clsx'

import { Week } from './Week'
import style from './WeeksList.module.css'

import { NodeDomainType } from 'features/menu'

type PropsType = {
  isOpen: boolean
  weeks: NodeDomainType[]
}

export const WeeksList = ({ isOpen, weeks }: PropsType) => {
  return (
    <ul className={clsx(style.list, isOpen && style['list--open'])}>
      {weeks.map(week => (
        <Week key={week.id} week={week} />
      ))}
    </ul>
  )
}
