import React, { ReactNode } from 'react'

import style from './Main.module.css'

type PropsType = {
  children: ReactNode
}

export const Main = ({ children }: PropsType) => {
  return (
    <main className={style.main} id="main">
      <div className="container">
        <div className={style.grid}>{children}</div>
      </div>
    </main>
  )
}
