import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { appReducer } from 'app/app-slice'
import { authReducer } from 'features/auth/auth-slice'
import { contentReducer } from 'features/content/content-slice'
import { menuReducer } from 'features/menu/menu-slice'

export const rootReducer = combineReducers({
  app: appReducer,
  menu: menuReducer,
  content: contentReducer,
  auth: authReducer,
})

export const store = configureStore({
  reducer: rootReducer,
})

export type StoreType = typeof store
export type AppRootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
