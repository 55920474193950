import { Route, Routes } from 'react-router-dom'

import { Content } from 'features/content'
import { Layout } from 'layout'

export enum Paths {
  Base = '/',
  Content = '/:id',
}

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={Paths.Base} element={<Layout />}>
        <Route path="/:courseSlag">
          <Route path="/:courseSlag/:id" element={<Content />} />
        </Route>
      </Route>
    </Routes>
  )
}
