import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const appSlice = createSlice({
  name: 'app',
  initialState: {
    error: null as null | string,
  },
  reducers: {
    setError: (state, action: PayloadAction<{ error: string | null }>) => {
      state.error = action.payload.error
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        action => action.type.endsWith('/pending'),
        state => {
          state.error = null
        }
      )
      .addMatcher(
        action => action.type.endsWith('/fulfilled'),
        state => {
          state.error = null
        }
      )
      .addMatcher(
        action => action.type.endsWith('/rejected') && action.type !== 'menu/authMe/rejected',
        (state, action: PayloadAction<{ error: string }>) => {
          state.error = action.payload.error
        }
      )
  },
})

export const appReducer = appSlice.reducer
