import { useEffect } from 'react'

import { toast } from 'react-toastify'

import { selectAppError } from 'app/app-selectors'
import { useAppSelector } from 'common/hooks'

export const useErrorHandling = () => {
  const error = useAppSelector(selectAppError)

  useEffect(() => {
    toast.error(error)
  }, [error])
}
