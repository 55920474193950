import React, { useId } from 'react'

import { Checkbox, Chip } from '@it-incubator/ui-kit'

import style from './Task.module.css'
import { TaskContent } from './TaskContent'
import { useTaskHandler } from './useTaskHandler'

import { useAppSelector } from 'common/hooks'
import { NodeType } from 'common/types'
import { capitalizeFirstLetter } from 'common/utils'
import { selectCompletedTasks } from 'features/menu'

type PropsType = {
  task: NodeType
}

export const Task = ({ task }: PropsType) => {
  const completedTasks = useAppSelector(selectCompletedTasks)
  const isChecked = completedTasks.includes(task.id)

  const { status, checkboxChangeHandler } = useTaskHandler(task)
  const id = useId()

  return (
    <li className={style.box}>
      <Checkbox
        checked={isChecked}
        disabled={status === 'loading'}
        onChange={checkboxChangeHandler}
        id={id}
      />
      <Chip
        style={{ marginTop: '6px', lineHeight: '24px' }}
        label={capitalizeFirstLetter(task.meta?.contentType!)}
        color={getBudgeColor(task.meta?.contentType!)}
      />
      <TaskContent link={task.link} title={task.title} description={task.description!} />
    </li>
  )
}

type Color = 'blue' | 'green' | 'brown'

const getBudgeColor = (type: string): Color => {
  let color: Color

  switch (type) {
    case 'video':
      color = 'blue'
      break
    case 'course':
      color = 'green'
      break
    default:
      color = 'brown'
  }

  return color
}
