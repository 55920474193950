import React from 'react'

import clsx from 'clsx'

import { Subject } from './Subject'
import style from './SubjectsList.module.css'

import { NodeDomainType } from 'features/menu'

type PropsType = {
  isOpen: boolean
  subjects: NodeDomainType[]
  weekLevel: number
  weekId: string
}

export const SubjectsList = ({ isOpen, subjects, weekLevel, weekId }: PropsType) => {
  return (
    <ul className={clsx(style.list, isOpen && style['list--open'])}>
      {subjects.map(subject => (
        <Subject key={subject.id} subject={subject} weekLevel={weekLevel} weekId={weekId} />
      ))}
    </ul>
  )
}
