import { useEffect } from 'react'

import { useActions } from 'common/hooks'
import { menuActions, menuThunks } from 'features/menu/index'

export const useInitialFetching = () => {
  const { fetchSprints, authMe, fetchCompletedTasks } = useActions(menuThunks)
  const { calculateResults, setMenuStatus } = useActions(menuActions)

  useEffect(() => {
    authMe({})
      .unwrap()
      .finally(() => {
        return fetchSprints({}).unwrap()
      })
      .then(() => {
        return fetchCompletedTasks({}).unwrap()
      })
      .then(() => {
        calculateResults()
      })
      .finally(() => {
        setMenuStatus('succeeded')
      })
  }, [])
}
