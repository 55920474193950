import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { CoursesEnum } from '../../../layout/Banner/Banner'
import { authActions } from '../../auth/auth-slice'

import { getCourseIdFromMap } from 'common/constants'
import { useActions, useAppDispatch, useAppSelector } from 'common/hooks'
import { selectCourseId } from 'features/auth/auth-selectors'
import { selectIsResultsCalculated, selectSprints } from 'features/menu'

/**
 * Автоматический роутинг к первой невыполненной теме, когда будут загружены выполенные задания и подсчитаны результаты.
 * Если все темы выполнены - роутинг к последней теме доступной по уровню
 */
export const useSubjectAutoNavigate = () => {
  const navigate = useNavigate()
  const isResultsCalculated = useAppSelector(selectIsResultsCalculated)
  const sprints = useAppSelector(selectSprints)
  const USER_LEVEL = 100
  const { setCourseId } = useActions(authActions)
  const { courseSlag, id } = useParams()
  const courseId = useAppSelector(selectCourseId) as CoursesEnum
  const menuStatus = useAppSelector(state => state.menu.status)

  if (!courseSlag || !courseId) {
    navigate('/front-end')
  }

  setCourseId({ courseId: getCourseIdFromMap(courseSlag)! })

  useEffect(() => {
    if (id) return

    if (isResultsCalculated) {
      const firstUnfinishedWeek = sprints
        .find(sprint => !sprint.isDone && sprint.level <= USER_LEVEL)
        ?.children?.find(week => !week.isDone && week.level <= USER_LEVEL)

      const firstUnfinishedSubject = firstUnfinishedWeek?.children?.find(subject => !subject.isDone)

      const subjectForNavigate =
        firstUnfinishedSubject ||
        sprints
          .findLast(sprint => sprint.level <= USER_LEVEL)
          ?.children?.findLast(week => week.level <= USER_LEVEL)
          ?.children?.findLast(subject => subject.level <= USER_LEVEL)

      const weekId = firstUnfinishedSubject
        ? firstUnfinishedWeek?.id
        : sprints
            .findLast(sprint => sprint.level <= USER_LEVEL)
            ?.children?.findLast(week => week.level <= USER_LEVEL)?.id

      if (subjectForNavigate) {
        navigate(`${courseSlag}/${subjectForNavigate?.id}`) // ?weekId=${weekId}
      }
    }

    if (!isResultsCalculated && menuStatus === 'succeeded') {
      const firstSubjectId = sprints[0]?.children?.[0]?.children?.[0]?.id

      navigate(`${courseSlag}${firstSubjectId ? `/${firstSubjectId}` : ''}`)
    }
  }, [isResultsCalculated, menuStatus])
}
