import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppRootState } from '../../app/store'
import { authService, Profile } from '../../common/api/incubator-api'

import { menuAPI } from './menu-api'

import { traineeAPI, AuthMeResponse } from 'common/api/api'
import { NodeMenuType } from 'common/types'
import { thunkTryCatch } from 'common/utils'

export const fetchSprints = createAsyncThunk<NodeMenuType[]>(
  'menu/fetchSprints',
  async (_, thunkAPI) => {
    return thunkTryCatch(thunkAPI, async () => {
      const state = thunkAPI.getState() as AppRootState
      const courseId = state.auth.courseId
      const response = await menuAPI.getSprints(courseId)

      return response.data
    })
  }
)

export const authMe = createAsyncThunk<Profile, void>('menu/authMe', async (_, thunkAPI) => {
  return thunkTryCatch(thunkAPI, async () => {
    const response = await authService.me()

    if (!response.data) {
      throw new Error('Response data is empty')
    }

    return response.data.data
  })
})

export const fetchCompletedTasks = createAsyncThunk<AuthMeResponse>(
  'menu/fetchCompletedTasks',
  async (_, thunkAPI) => {
    return thunkTryCatch(thunkAPI, async () => {
      const response = await traineeAPI.fetchTasks()

      return response.data
    })
  }
)

export const menuThunks = {
  fetchCompletedTasks,
  fetchSprints,
  authMe,
}
