import { createSelector } from '@reduxjs/toolkit'

import { NodeDomainType } from './menu-slice'

import { AppRootState } from 'app/store'

export const selectSearch = (state: AppRootState) => state.menu.search
export const selectIsResultsCalculated = (state: AppRootState) => state.menu.isResultsCalculated
export const selectMenuStatus = (state: AppRootState) => state.menu.status
export const selectCompletedTasks = (state: AppRootState) => state.menu.completedTasks
export const selectSprints = (state: AppRootState) => state.menu.sprints
export const selectCurrentWeekId = (state: AppRootState) => state.menu.currentWeekId
export const selectCurrentWeekLevel = (state: AppRootState) => {
  const weekId = state.menu.currentWeekId
  let currentWeek: NodeDomainType

  const currentSprint = state.menu.sprints.find(sprint =>
    sprint?.children?.some(week => {
      if (week.id === weekId) {
        currentWeek = week

        return true
      }
    })
  )

  // @ts-ignore
  return currentWeek?.level
}

export const selectFilteredSubjects = (userLevel: number) =>
  createSelector([selectSprints, selectSearch], (sprints, search) => {
    const subjects: NodeDomainType[] = []

    sprints.forEach(sprint =>
      sprint.children?.forEach(week => {
        if (week.children && week.level <= userLevel) subjects.push(...week.children)
      })
    )

    const filteredSubjects = subjects.filter(subject =>
      subject.title.toLowerCase().includes(search.toLowerCase())
    )

    const ids = filteredSubjects.map(subjects => subjects.id)
    const uniqueSubjects = filteredSubjects.filter(
      (subject, index) => !ids.includes(subject.id, index + 1)
    )

    return uniqueSubjects
  })

/**
 * Возвращает уровень последней доступной недели, в которой встречается subjectId: необходимо для определения недели при поиске
 */
export const selectLastWeekIdForSubject =
  ({ subjectId, userLevel }: { subjectId: string; userLevel: number }) =>
  (state: AppRootState) => {
    let weekId

    state.menu.sprints.findLast((sprint: NodeDomainType) =>
      sprint.children?.findLast((week: NodeDomainType) =>
        week?.children?.some(subject => {
          if (subject.id === subjectId && week.level <= userLevel) {
            weekId = week.id

            return true
          }
        })
      )
    )

    return weekId
  }
