import { createSelector } from '@reduxjs/toolkit'

import { AppRootState } from 'app/store'

export const selectSubject = (state: AppRootState) => state.content.subject

export const selectContentStatus = (state: AppRootState) => state.content.status

export const selectTasksByCategory = createSelector(
  (state: AppRootState, category: string) =>
    state.content.subject?.children?.filter(task => task.category === category),
  tasks => {
    return tasks
  }
)
