import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'

import { NodeMenuType, RequestStatus } from 'common/types'
import { calculateResults, convertToTree } from 'common/utils'
import { authMe, fetchCompletedTasks, fetchSprints } from 'features/menu'

export type NodeDomainType = Omit<NodeMenuType, 'children'> & {
  isDone?: boolean
  result?: number
  children?: NodeDomainType[]
}

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    sprints: [] as NodeDomainType[],
    completedTasks: [] as string[],
    isResultsCalculated: false,
    search: '',
    status: 'idle' as RequestStatus,
    currentWeekId: null as null | string,
  },
  reducers: {
    setMenuStatus(state, action: PayloadAction<RequestStatus>) {
      state.status = action.payload
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload
    },
    calculateResults(state) {
      state.sprints = calculateResults(state.sprints, state.completedTasks)
      state.isResultsCalculated = true
    },
    setCurrentWeekId(state, action: PayloadAction<string>) {
      state.currentWeekId = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSprints.fulfilled, (state, action) => {
      const tree = convertToTree(action.payload)

      state.sprints = tree.map(sprint => ({
        ...sprint,
        children: sprint.children?.map(week => ({
          ...week,
          children: week.children?.map(subject => ({
            ...subject,
            result: 0,
            isDone: false,
          })),
          isDone: false,
        })),
        isDone: false,
      }))
    })
    builder.addCase(fetchCompletedTasks.fulfilled, (state, action) => {
      state.completedTasks = action.payload.doneRoadmap
    })
    builder.addMatcher(isAnyOf(fetchSprints.pending, authMe.pending), state => {
      state.status = 'loading'
    })
    builder.addMatcher(isAnyOf(fetchSprints.rejected, authMe.rejected), (state, action) => {
      state.status = 'failed'
    })
  },
})

export const menuActions = menuSlice.actions
export const menuReducer = menuSlice.reducer
