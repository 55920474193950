import axios from 'axios'

import { authService, handleAuthResponseErrors } from './incubator-api'

import { StoreType } from 'app/store'

export const instance = axios.create({
  baseURL: process.env.REACT_APP_ROADMAP_API_URL,
  withCredentials: true,
})

let store: StoreType

export const injectStore = (_store: StoreType) => {
  store = _store
}

instance.interceptors.request.use(config => {
  const state = store.getState()
  const courseId = state.auth.courseId

  config.headers['course-id'] = courseId
  config.headers['user-token'] = authService.getAccessToken()

  return config
})

instance.interceptors.response.use(undefined, error => handleAuthResponseErrors(error))
export const traineeAPI = {
  fetchTasks() {
    return instance.get<AuthMeResponse>('trainee/me')
  },
}

export type AuthMeResponse = {
  traineeId: string
  level: number
  doneRoadmap: string[]
}
