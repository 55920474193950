import { NodeMenuType } from '../types'

type TreeNode = NodeMenuType & {
  children?: NodeMenuType[]
}

export type Tree = TreeNode[]

export const convertToTree = (roadmap: NodeMenuType[]): TreeNode[] => {
  const data = roadmap.sort((prev, next) => prev.deep - next.deep)

  let tree: Tree = []

  for (let i = 0; i < data.length; i++) {
    let node = data[i]
    let parents = findParents(node, tree)

    if (parents.length !== 0) {
      parents.forEach(parent => {
        if (!parent.children) parent.children = []
        parent.children.push(node)
      })
    } else {
      tree.push(node)
    }
  }

  // return tree.sort((prev, next) => prev.level - next.level)
  return sortArray(tree) as TreeNode[]
}

const findParents = (node: TreeNode, tree: Tree) => {
  const parents = []

  for (let i = 0; i < tree.length; i++) {
    const parent = tree[i]

    if (parent.nodesIds.some(item => item.id === node.id)) {
      parents.push(parent)
    }
    if (parent.children) {
      const potentialParents = findParents(node, parent.children)

      if (potentialParents.length !== 0) {
        potentialParents.forEach(item => parents.push(item))
      }
    }
  }

  return parents
}

type Item = {
  order?: number
  [key: string]: any
}

function sortArray(array: Item[]): Item[] {
  array.sort((a, b) => a.order! - b.order!)
  array.forEach(item => {
    for (let key in item) {
      if (Array.isArray(item[key])) {
        item[key] = sortArray(item[key] as Item[])
      }
    }
  })

  return array
}
