import React from 'react'

import clsx from 'clsx'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import style from './Layout.module.css'

import { Menu } from 'features/menu'
import { Header, Main } from 'layout'
import { Footer } from 'layout/Footer/Footer'

export const Layout = () => {
  return (
    <div className={clsx(style.layout)}>
      <Header />
      <Main>
        <Menu />
        <Outlet />
      </Main>
      <Footer />
      <ToastContainer hideProgressBar position="bottom-right" autoClose={5000} theme="light" />
    </div>
  )
}
