import React from 'react'

import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { injectStore } from './common/api/api'

import { App } from 'app/App'
import '@it-incubator/ui-kit/dist/style.css'
import './index.css'
import { store } from 'app/store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

injectStore(store)

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
)
