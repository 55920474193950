import { useEffect } from 'react'

import { useActions, useAppSelector } from 'common/hooks'
import { contentActions, contentThunks } from 'features/content/index'
import { selectMenuStatus } from 'features/menu'

export const useFetchSubject = (id?: string) => {
  const { fetchSubject } = useActions(contentThunks)
  const { resetContent } = useActions(contentActions)
  const menuStatus = useAppSelector(selectMenuStatus)

  useEffect(() => {
    resetContent()
    if (id && menuStatus === 'succeeded') {
      fetchSubject({ id })
    }
  }, [id, menuStatus])
}
