import React from 'react'

import { Task } from './Task'
import style from './TasksList.module.css'

import { NodeType } from 'common/types'

type PropsType = {
  tasks: NodeType[]
}

export const TasksList = ({ tasks }: PropsType) => {
  const sortedTasks = [...tasks]?.sort((a, b) => a.order! - b.order!)

  return (
    <ul className={style['tasks-list']}>
      {sortedTasks?.map(task => (
        <Task key={task.id} task={task} />
      ))}
    </ul>
  )
}
