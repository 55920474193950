import { createAsyncThunk } from '@reduxjs/toolkit'

import { contentAPI } from './content-api'

import { NodeType } from 'common/types'
import { thunkTryCatch } from 'common/utils'
import { GetSubjectParams, menuAPI } from 'features/menu'

export const fetchSubject = createAsyncThunk<NodeType, GetSubjectParams>(
  'content/fetchSubject',
  async (params, thunkAPI) => {
    return thunkTryCatch(thunkAPI, async () => {
      const res = await menuAPI.getSubject(params)

      return res.data
    })
  }
)

export const addCompletedTask = createAsyncThunk<string, { id: string }>(
  'content/addCompletedTask',
  async ({ id }, thunkAPI) => {
    return thunkTryCatch(thunkAPI, async () => {
      await contentAPI.addCompletedTask({ id })

      return id
    })
  }
)

export const removeCompletedTask = createAsyncThunk<string, { id: string }>(
  'content/removeCompletedTask',
  async ({ id }, thunkAPI) => {
    return thunkTryCatch(thunkAPI, async () => {
      await contentAPI.removeCompletedTask({ id })

      return id
    })
  }
)

export const contentThunks = {
  fetchSubject,
  addCompletedTask,
  removeCompletedTask,
}
