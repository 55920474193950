import { useSearchParams } from 'react-router-dom'

import { useActions } from 'common/hooks'
import { menuActions } from 'features/menu'

export const useSetWeekIdFromSearchParams = () => {
  const [searchParams] = useSearchParams()
  const { setCurrentWeekId } = useActions(menuActions)

  setCurrentWeekId(searchParams.get('weekId')!)
}
