import { Skeleton } from '@it-incubator/ui-kit'

import s from './SkeletonMenu.module.css'

export const SkeletonMenu = () => {
  return (
    <div className={s.box}>
      <div className={s.searchRow}>
        <Skeleton height={36} />
      </div>
      <div className={s.list}>
        <Skeleton count={12} height={30} width={220} style={{ marginBottom: '10px' }} />
      </div>
    </div>
  )
}
