import React, { FC } from 'react'

import { Modal, ModalProps } from '@it-incubator/ui-kit'

import { useRedirectLink } from '../../../../common/hooks'

import style from './Auth.module.css'

import authModalImage from 'assets/images/auth-modal-image.png'
import { ButtonLink } from 'common/components/ButtonLink/ButtonLink'

export const AuthModal: FC<ModalProps> = props => {
  const redirectLink = useRedirectLink()

  return (
    <Modal {...props}>
      <div className={style.box}>
        <img width={371} height={276} src={authModalImage} alt="" />
        <p className={style.text}>
          Для того, чтобы использовать всю функциональность <b>RoadMap</b> вам необходимо
          <b> авторизоваться</b>
        </p>
        <ButtonLink href={redirectLink}>Авторизоваться</ButtonLink>
      </div>
    </Modal>
  )
}
