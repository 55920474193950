import React, { ChangeEvent } from 'react'

import { TextField } from '@it-incubator/ui-kit'

import style from './Search.module.css'

import { useActions, useAppSelector } from 'common/hooks'
import { menuActions, selectSearch } from 'features/menu'

export const MenuSearch = () => {
  const search = useAppSelector(selectSearch)
  const { setSearch } = useActions(menuActions)

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value)
  }

  return (
    <div className={style.box}>
      <TextField
        search={true}
        value={search}
        onChange={handleInput}
        placeholder="Поиск"
        onClearClick={() => setSearch('')}
      />
    </div>
  )
}
