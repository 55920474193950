import { useContext, useState } from 'react'

import { useActions, useAppSelector } from 'common/hooks'
import { NodeType } from 'common/types'
import { selectIsLoggedIn } from 'features/auth/auth-selectors'
import { AuthModalContext, contentThunks } from 'features/content'
import { menuActions, menuThunks } from 'features/menu'

export const useTaskHandler = (task: NodeType) => {
  const { addCompletedTask, removeCompletedTask } = useActions(contentThunks)
  const { fetchCompletedTasks } = useActions(menuThunks)
  const { calculateResults } = useActions(menuActions)

  // const token = useHostSelector(state => state.hostAuth.user.baseToken)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const openAuthModal = useContext(AuthModalContext)!

  const [status, setStatus] = useState('idle')

  const checkboxChangeHandler = (checked: boolean) => {
    if (!isLoggedIn) {
      openAuthModal()

      return
    }

    setStatus('loading')
    if (checked) {
      addCompletedTask({ id: task.id }).then(() => {
        fetchCompletedTasks({}).then(() => {
          setStatus('succeeded')
          calculateResults()
        })
      })
    } else {
      removeCompletedTask({ id: task.id }).then(() => {
        fetchCompletedTasks({}).then(() => {
          setStatus('succeeded')
          calculateResults()
        })
      })
    }
  }

  return {
    status,
    checkboxChangeHandler,
  }
}
