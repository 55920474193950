import React from 'react'

import { useSearchParams } from 'react-router-dom'

import style from './ContentFooter.module.css'

import { useAppSelector } from 'common/hooks'
import { selectSubject } from 'features/content'
import { selectCompletedTasks } from 'features/menu'

export const ContentFooter = () => {
  const subject = useAppSelector(selectSubject)
  const completedTasks = useAppSelector(selectCompletedTasks)
  const [searchParams] = useSearchParams()
  const weekLevel = Number(searchParams.get('weekLevel')) || 100

  const availableBasicTasks = subject?.children?.filter(
    task => task.category === 'basic' && task.level <= weekLevel
  )

  const numberCompletedTasks = availableBasicTasks?.filter(task =>
    completedTasks.includes(task.id)
  ).length

  if (!availableBasicTasks || availableBasicTasks?.length === 0) {
    return null
  }

  return (
    <footer className={style.box}>
      <p className={style.text}>
        Изучено: {numberCompletedTasks} из {availableBasicTasks?.length}
      </p>
    </footer>
  )
}
