import React from 'react'

import clsx from 'clsx'
import { Link, useParams } from 'react-router-dom'

import { selectCourseId } from '../../../../../../../auth/auth-selectors'

import style from './Subject.module.css'
import { useIsCurrentSubject } from './useIsCurrentSubject'

import { CircleResult, SucceedCheck } from 'common/components'
import { useAppSelector } from 'common/hooks'
import { selectContentStatus } from 'features/content'
import { NodeDomainType, selectLastWeekIdForSubject } from 'features/menu'

type PropsType = {
  subject: NodeDomainType
  weekLevel?: number
  weekId?: string
}

export const Subject = ({ subject, weekLevel, weekId }: PropsType) => {
  const isCurrentSubject = useIsCurrentSubject({ weekId, subjectId: subject.id })
  const { courseSlag } = useParams()

  const result = Math.floor((subject.result || 0) * 100)

  const userLevel = 100
  const isAvailable = subject.level <= userLevel

  const contentStatus = useAppSelector(selectContentStatus)
  const isLoading = contentStatus === 'loading'

  return (
    <li
      className={clsx(
        style.box,
        'subject-item',
        !isAvailable && style['box--isUnavailable'],
        isCurrentSubject && style.current,
        isLoading && style.loading
      )}
    >
      <Link
        to={`${courseSlag}/${subject.id}`} // ?weekId=${weekId || searchLastWeekId}
        className={clsx(style.link, isLoading && style.loading)}
      >
        <div className={style.content}>
          {subject.isDone ? (
            <SucceedCheck size={24} className={style['succeed-check']} />
          ) : (
            <CircleResult
              value={result}
              size={36}
              textClassName={clsx(result === 0 && style['inactive-result-text'])}
              isCurrent={isCurrentSubject}
            />
          )}
          <b className={clsx(style.text)}>{subject.title}</b>
        </div>
      </Link>
    </li>
  )
}
