import React, { forwardRef, Ref } from 'react'

import { ReadMore } from '@it-incubator/ui-kit'

import style from './ContentHeader.module.css'

import { useAppSelector } from 'common/hooks'
import { selectSubject } from 'features/content'

type PropsType = {}

export const ContentHeader = forwardRef(({}: PropsType, ref: Ref<HTMLElement>) => {
  const subject = useAppSelector(selectSubject)

  if (!subject) return null

  return (
    <header className={style.box} id="roadmap-content-header" ref={ref}>
      <h2 className={style.title}>{subject?.title}</h2>
      {subject?.description && (
        <p className={style.description}>
          <ReadMore text={subject.description} maxLength={160} />
        </p>
      )}
    </header>
  )
})
