import React from 'react'

import { AppRoutes } from './AppRoutes/AppRoutes'
import { Head } from './Head'

import { useErrorHandling } from 'app/hooks/useErrorHandling'

export const App = () => {
  useErrorHandling()

  return (
    <>
      <Head />
      <AppRoutes />
    </>
  )
}
