import React from 'react'

import { Scrollbar } from '@it-incubator/ui-kit'

import { Sprint } from './Sprint'
import { Subject } from './Sprint/WeeksList/Week/SubjectsList/Subject'
import style from './SprintsList.module.css'

import { useAppSelector } from 'common/hooks'
import { selectFilteredSubjects, selectSearch, selectSprints } from 'features/menu'

export const SprintsList = () => {
  const sprints = useAppSelector(selectSprints)
  const userLevel = 100
  const filteredSubjects = useAppSelector(selectFilteredSubjects(userLevel))
  const search = useAppSelector(selectSearch)

  return (
    <Scrollbar className={style.scrollbar}>
      <ul className={style['sprints-list']}>
        {search.length !== 0
          ? filteredSubjects.map(subject => <Subject key={subject.id} subject={subject} />)
          : sprints.map(sprint => <Sprint key={sprint.id} sprint={sprint} />)}
      </ul>
    </Scrollbar>
  )
}
