import { instance } from 'common/api/api'

export const contentAPI = {
  addCompletedTask({ id }: AddCompletedTaskParam) {
    return instance.post(`trainee`, {
      taskId: id,
    })
  },
  removeCompletedTask({ id }: RemoveCompletedTaskParam) {
    return instance.delete(`trainee`, {
      data: {
        taskId: id,
      },
    })
  },
}

type AddCompletedTaskParam = {
  id: string
}

type RemoveCompletedTaskParam = {
  id: string
}
