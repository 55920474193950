import React, { createContext, useState } from 'react'

import { Scrollbar } from '@it-incubator/ui-kit'

import style from './Categories.module.css'
import { Category } from './Category/Category'

import { CATEGORIES } from 'common/constants'
import { AuthModal } from 'features/content/Categories/AuthModal/AuthModal'

const FOOTER_HEIGHT = 60
const INTERFACE_HEIGHT = 612

type PropsType = {
  headerHeight?: number
}

export const AuthModalContext = createContext<null | Function>(null)

export const Categories = ({ headerHeight = 0 }: PropsType) => {
  const [authModalOpen, setAuthModalOpen] = useState(false)

  return (
    <>
      <Scrollbar maxHeight={INTERFACE_HEIGHT - FOOTER_HEIGHT - headerHeight}>
        <AuthModalContext.Provider value={() => setAuthModalOpen(true)}>
          <ul className={style.list}>
            {CATEGORIES.map(category => (
              <Category key={category} category={category} />
            ))}
          </ul>
        </AuthModalContext.Provider>
      </Scrollbar>
      <AuthModal open={authModalOpen} title="Авторизация" onClose={() => setAuthModalOpen(false)} />
    </>
  )
}
